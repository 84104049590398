import * as React from "react";
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import ArticleComponent from "../components/ArticleComponent";
import TextWrapper from "../components/TextWrapper";

import FacebookIcon from "../components/FacebookIcon";
import InstagramIcon from "../components/InstagramIcon";

const title = css`
  margin-bottom: 2px;
`;

const socialIcon = css`
  margin-right: 10px;
  margin-top: 10px;
`;

const IndexPage = () => {
  const { map } = useStaticQuery(
    graphql`
      query {
        map: file(relativePath: { eq: "mapa_grunwald.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  );
  return (
    <ArticleComponent title="Fundacja">
      <TextWrapper>
        <article>
          <h1 css={title}>Fundacja</h1>
          <p>
            Inicjatywę Plac Grunwaldzki OD NOWA zainicjowała i prowadzi Fundacja
            Ładne Historie, której zespół współpracuje z mieszkankami i
            mieszkańcami osiedla, lokalnymi liderami i grupami oddolnymi, a
            także instytucjami i placówkami, dla których Plac Grunwaldzki jest
            sercem Wrocławia.
          </p>
          <p>
            Masz pytania? Skontaktuj się z nami!
            placgrunwaldzki@ladnehistorie.pl
          </p>
          <p>
            <strong>Adres:</strong>
            <br />
            Plac Grunwaldzki OD NOWA
            <br />
            ul. Marii Skłodowskiej-Curie 63a
            <br />
            tel. 71 757 15 87
          </p>
          <Img fluid={map.childImageSharp.fluid} alt="Mapa dojazdu" />
          <p>
            Fundacja Ładne Historie to organizacja powołana w wyniku współpracy
            grupy animatorów, artystów, kuratorów, aktywistów i promotorów
            kultury. Naszym strategicznym celem jest kreowanie zmiany w myśleniu
            o edukacji kulturowej i społecznej, a także rozwijanie nowych
            narzędzi służących jej realizacji poprzez organizację
            interdyscyplinarnych działań. Nasze projekty opieramy na znajomości
            lokalnych potrzeb i kontekstów, realizujemy je w trybie wieloletnim,
            a każde z działań ma zachęcać uczestników do aktywnej postawy. Naszą
            pracę opieramy o relacje, budujemy poczucie tożsamości i więzi z
            tym, co lokalne, bliskie i istotne. Działania planujemy w taki
            sposób, by przyczyniały się do poprawy dostępności, różnorodności i
            innowacyjności oferty kulturalnej i społecznej. Nasz zróżnicowany
            program realizujemy z przekonania, że kultura ma nie tylko
            wzmacniać, integrować i budować lokalne społeczności, ale również
            wpływać na poprawę jakości i warunków, w których one funkcjonują na
            co dzień. Wierzymy, że to właśnie kultura – otwarta, dostępna i
            wynikająca z lokalnych potrzeb – jest narzędziem do kreowania
            pozytywnej zmiany społecznej w polskich regionach, miastach i na
            osiedlach.
          </p>
          <p>
            Projekt „Jak powstawał Plac Grunwaldzki? Wirtualne kompendium wiedzy
            o naszym osiedlu” stanowi rozwinięcie naszych w naszej ulubionej
            okolicy Wrocławia - jest to działanie, które zrealizowaliśmy w
            ramach programu Mikrogranty NGO, którego operatorami są Dolnośląska
            Federacja Organizacji Pozarządowych, Centrum Ds. Katastrof i Klęsk
            Żywiołowych „Tratwa”, Fundacja Wspierania Organizacji Pozarządowych
            „Umbrella” oraz Strefa Kultury Wroclaw.
          </p>
          <h4>Dane fundacji</h4>
          <p>
            Fundacja Ładne Historie |{" "}
            <a
              href="https://ladnehistorie.pl/"
              target="_blank"
              rel="noreferrer"
            >
              www.ladnehistorie.pl
            </a>
            <br />
            ul. Al. Niepodległości 12a/1, 58-100 Świdnica
            <br />
            KRS 0000640105 | REGON 365551622 | NIP 8842769171
            <br />
            <a
              href="https://www.facebook.com/PlacGrunwaldzkiODNOWA"
              target="_blank"
              rel="noreferrer"
              css={socialIcon}
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.instagram.com/placgrunwaldzkiod_nowa/"
              target="_blank"
              rel="noreferrer"
              css={socialIcon}
            >
              <InstagramIcon />
            </a>
          </p>
          <h4>Materiały do pobrania</h4>
          <p>
          <ul>
            <li><a href="https://placgrunwaldzki.com/assets/regulamin-korzystania-z-przestrzeni-plac-grunwaldzki-od-nowa.pdf"
              target="_blank" rel="noreferrer">Regulamin korzystania z przestrzeni Plac Grunwaldzki OD NOWA</a></li>

            <li><a href="https://placgrunwaldzki.com/assets/правила-та-положення-використання-простору-plac-grunwaldzki-od-nowa.pdf"
              target="_blank" rel="noreferrer">Правила та положення використання простору „Plac Grunwaldzki OD NOWA”</a></li>
            
            <li><a href="https://placgrunwaldzki.com/assets/rules-and-regulations-for-the-use-of-plac-grunwaldzki-od-nowa-space.pdf"
              target="_blank" rel="noreferrer">Rules and regulations for the use of Plac Grunwaldzki OD NOWA space</a></li>
            <li><a href="https://placgrunwaldzki.com/assets/polityka-ochrony-dzieci-przed-krzywdzeniem-w-fundacji-ladne-historie.pdf"
              target="_blank" rel="noreferrer">Polityka ochrony dzieci przed krzywdzeniem w Fundacji Ładne Historie</a></li>
            </ul>
            </p>
        </article>
      </TextWrapper>
    </ArticleComponent>
  );
};

export default IndexPage;
